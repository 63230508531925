import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetRequest, sendPostRequest } from './requestUtil';

// ! for dummy data, remove once blogDetails.tags return from API
import dummyTagsList from '../../types/tagDummyData.json';

// This returns a single blog by ID, with a list of discussions & reviews
export const getBlogById = createAsyncThunk(
  'get-blog-by-id',
  async (blogId: string | number | undefined): Promise<BlogPageSuccessType> => {
    /** // * API in development, not yet available

    // will be used to get blog details, discussions, and reviews
    const blogContent: BlogPageSuccessType = await sendGetRequest(
      `/api/blogs/${blogId}/detailview/`
    );
    */ // END

    // * currently using api data

    // consolidated blogDetails
    const blogDetails: BlogCard = await sendGetRequest(`/api/blogs/${blogId}/`);

    // paginated blogReviewsList
    const blogReviewsList: BlogReviewsListSuccessType = await sendGetRequest(
      `/api/blogs/${blogId}/reviews/`
    );

    // paginated blogDiscussionsList
    const blogDiscussionsList: BlogDiscussionsListSuccessType =
      await sendGetRequest(`/api/blogs/${blogId}/discussions/`);

    // adds dummy tags to blogDetails until API returns tags
    blogDetails.tags = dummyTagsList.results; // TODO: remove once tags return from API

    let blogPageData: BlogPageSuccessType = {
      blogDetails,
      blogDiscussionsList,
      blogReviewsList,
    };

    return blogPageData;
  }
);

export const uploadBlogImage = createAsyncThunk(
  'upload-blog-image',
  async (body: FormData, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/blogs/upload-image/', body, token);
  }
);

export const uploadBlog = createAsyncThunk(
  'upload-blog',
  async (uploadBlogData: UploadBlogRequestType) => {
    return sendPostRequest('/api/add-blog/', uploadBlogData);
  }
);
