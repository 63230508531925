import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SideDrawerButtonComponent from '../components/NavigationComponents/SideDrawerButtonComponent';
import { setIsSideDrawerOpen } from '../store/reducers/navigationReducer';
import Backdrop from './Backdrop';

const SideDrawer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isHomeSelected, setIsHomeSelected] = useState(false);
  // const [isNotificationsSelected, setIsNotificationsSelected] = useState(false);
  const [isUploadSelected, setIsUploadSelected] = useState(false);
  const [isDonateSelected, setIsDonateSelected] = useState(false);
  // const [isContactUsSelected, setIsContactUsSelected] = useState(false);
  const [isProfileSelected, setIsProfileSelected] = useState(false);

  const isSideDrawerOpen = useSelector(
    (state: AppState) => state.nav.isSideDrawerOpen
  );

  useEffect(() => {
    setIsOpen(isSideDrawerOpen);
  }, [isSideDrawerOpen]);

  const handleHomeNavButtonClick = () => {
    deselectAllNavButtons();
    setIsHomeSelected(true);
    navigate('/home');
    dispatch(setIsSideDrawerOpen(false));
  };

  // const handleNotificationsNavButtonClick = () => {
  //   deselectAllNavButtons();
  //   setIsNotificationsSelected(true);
  //   dispatch(setIsSideDrawerOpen(false));
  // };

  const handleUploadNavButtonClick = () => {
    deselectAllNavButtons();
    setIsUploadSelected(true);
    navigate('/add-blog');
    dispatch(setIsSideDrawerOpen(false));
  };
  const handleDonateNavButtonClick = () => {
    deselectAllNavButtons();
    setIsDonateSelected(true);
    navigate('/donate');
    dispatch(setIsSideDrawerOpen(false));
  };

  // const handleContactUsNavButtonClick = () => {
  //   deselectAllNavButtons();
  //   setIsContactUsSelected(true);
  //   dispatch(setIsSideDrawerOpen(false));
  // };

  const handleProfileNavButtonClick = () => {
    deselectAllNavButtons();
    setIsProfileSelected(true);
    navigate('/profile');
    dispatch(setIsSideDrawerOpen(false));
  };

  const deselectAllNavButtons = () => {
    // setIsContactUsSelected(false);
    setIsDonateSelected(false);
    setIsHomeSelected(false);
    // setIsNotificationsSelected(false);
    setIsProfileSelected(false);
    setIsUploadSelected(false);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    dispatch(setIsSideDrawerOpen(!isOpen));
  };

  return (
    <div>
      <div className={`side-drawer ${isSideDrawerOpen ? 'open' : ''}`}>
        <button onClick={toggleDrawer} className="close-button" />
        <div className="navigation-section">
          <SideDrawerButtonComponent
            buttonText="Home"
            iconName="home"
            isSelected={isHomeSelected}
            handleSideDrawerButtonClick={handleHomeNavButtonClick}
          />
          {/* <SideDrawerButtonComponent
            buttonText="Notifications"
            iconName="notifications"
            isSelected={isNotificationsSelected}
            handleSideDrawerButtonClick={handleNotificationsNavButtonClick}
          /> */}
          <SideDrawerButtonComponent
            buttonText="Upload a Blog"
            iconName="upload"
            isSelected={isUploadSelected}
            handleSideDrawerButtonClick={handleUploadNavButtonClick}
          />
          <SideDrawerButtonComponent
            buttonText="Donate"
            iconName="donate"
            isSelected={isDonateSelected}
            handleSideDrawerButtonClick={handleDonateNavButtonClick}
          />
          {/* <SideDrawerButtonComponent
            buttonText="Contact Us"
            iconName="contact-us-icon"
            isSelected={isContactUsSelected}
            handleSideDrawerButtonClick={handleContactUsNavButtonClick}
          /> */}
          <SideDrawerButtonComponent
            buttonText="Profile"
            iconName="profile"
            isSelected={isProfileSelected}
            handleSideDrawerButtonClick={handleProfileNavButtonClick}
          />
        </div>
      </div>
      <Backdrop show={isOpen} closeSideDrawer={toggleDrawer} />
    </div>
  );
};

export default SideDrawer;
