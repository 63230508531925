import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';

import { getBlogById, uploadBlogImage } from '../actions/blogPageActions';

// * initial state
const initialState: BlogPageStateType = {
  blogDetails: {},
  blogTagsList: {},
  blogDiscussionsList: {},
  blogReviewsList: {},
  isBlogListLoading: false,
  blogListErrorMessage: '',
} as any;

// * create slice state
const blogDetailSlice = createSlice({
  name: 'blogDetailStateSlice',
  initialState,
  reducers: {
    resetBlogDetailState: () => initialState,
  },
  extraReducers: builder => {
    blogDetails(builder);
    uploadBlogImageCases(builder);
    // getMoreDiscussions(builder);
    // getMoreReviews(builder);
  },
});

// TODO: add ActionReducerMapBuilder type
const blogDetails = (builder: ActionReducerMapBuilder<BlogPageStateType>) => {
  // PENDING
  builder.addCase(getBlogById.pending, (state: BlogPageStateType) => {
    state.isBlogDetailLoading = true;
  });

  // FULFILLED
  builder.addCase(
    getBlogById.fulfilled,
    (state: BlogPageStateType, action: PayloadAction<BlogPageSuccessType>) => {
      state.isBlogDetailLoading = false;
      state.blogDetails = action.payload.blogDetails;
      state.blogDiscussionsList = action.payload.blogDiscussionsList;
      state.blogReviewsList = action.payload.blogReviewsList;
    }
  );

  // REJECTED
  // TODO: add type for action PayloadAction<UserAuthErrorType>
  builder.addCase(
    getBlogById.rejected,
    (state: BlogPageStateType, action: any) => {
      state.isBlogDetailLoading = false;
      state.blogDetailErrorMessage = action.payload.detail;
      // TODO: add blogDetail error state here
    }
  );
};

const uploadBlogImageCases = (
  builder: ActionReducerMapBuilder<BlogPageStateType>
) => {
  builder.addCase(uploadBlogImage.pending, (state: BlogPageStateType) => {
    // state.isBlogImageUploading = true;
  });
  builder.addCase(
    uploadBlogImage.fulfilled,
    (state: BlogPageStateType, action: PayloadAction<any>) => {
      // state.isBlogImageUploading = false;
      state.blogDetails.image_blog_page = action.payload.blogImageUrl;
    }
  );
  builder.addCase(
    uploadBlogImage.rejected,
    (state: BlogPageStateType, action: any) => {
      // state.isBlogImageUploading = false;
      // state.blogImageUploadError = action.payload.detail;
      console.log('Error uploading blog image: ', action.payload);
    }
  );

  return builder;
};

export const { resetBlogDetailState } = blogDetailSlice.actions;
export default blogDetailSlice.reducer;
