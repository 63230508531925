import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isInAuthFlow: false,
  isSideDrawerOpen: false,
} satisfies NavigationStateType as NavigationStateType;

const navigationSlice = createSlice({
  name: 'navigationSlice',
  initialState,
  reducers: {
    setIsInAuthFlow: (
      state: NavigationStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isInAuthFlow = action.payload;
    },
    setIsSideDrawerOpen: (
      state: NavigationStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isSideDrawerOpen = action.payload;
    },
  },
});

export const { setIsInAuthFlow, setIsSideDrawerOpen } = navigationSlice.actions;

export default navigationSlice.reducer;
