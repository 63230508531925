import SideDrawer from './SideDrawer';
import { useSelector } from 'react-redux';

const Layout = (props: LayoutProps) => {
  const isSideDrawerOpen = useSelector(
    (state: AppState) => state.nav.isSideDrawerOpen
  );

  const contentClass = isSideDrawerOpen
    ? 'content side-drawer-open'
    : 'content';

  return (
    <div className="layout">
      <SideDrawer />
      <div className={contentClass}>{props.children}</div>
    </div>
  );
};

export default Layout;
