import axios from 'axios';

export const getCookie = (name: string) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const sendGetRequest = async (url: string) => {
  try {
    return (await axios.get(url)).data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const sendPostRequest = async (
  url: string,
  body: object,
  token: string | null = null
) => {
  try {
    let headers: any = {};
    if (token) headers['Authorization'] = `Token ${token}`;
    return (
      await axios.post(url, body, {
        headers: headers,
      })
    ).data;
  } catch (error: any) {
    return Promise.reject(error.response.data);
  }
};
