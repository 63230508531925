import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';

import { getBlogList } from '../actions/blogListActions';

const initialState = {
  blogList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  isBlogListLoading: false,
  blogListErrorMessage: '',
} as BlogListStateType;

// * create slice state
const blogListSlice = createSlice({
  name: 'blogListStateSlice',
  initialState,
  reducers: {
    resetBlogListState: () => initialState,
  },
  extraReducers: builder => {
    blogLists(builder);
  },
});

const blogLists = (builder: ActionReducerMapBuilder<BlogListStateType>) => {
  // PENDING
  builder.addCase(getBlogList.pending, (state: BlogListStateType) => {
    state.isBlogListLoading = true;
  });

  // FULFILLED
  // TODO: add type for action PayloadAction<any>
  builder.addCase(
    getBlogList.fulfilled,
    (state: BlogListStateType, action: PayloadAction<BlogListSuccessType>) => {
      state.isBlogListLoading = false;
      state.blogList = action.payload;
    }
  );

  // REJECTED
  // TODO: add type for action PayloadAction<UserAuthErrorType>
  builder.addCase(
    getBlogList.rejected,
    (state: BlogListStateType, action: any) => {
      state.isBlogListLoading = false;
      state.blogListErrorMessage = action.payload?.detail;
    }
  );

  return builder;
};

export const { resetBlogListState } = blogListSlice.actions;
export default blogListSlice.reducer;
