import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';
import { uploadProfilePicture } from '../actions/authActions';

const initialState = {
  profilePicture: '',
  bio: '',
  interests: [],
} satisfies ProfileStateType as ProfileStateType;

const profileSlice = createSlice({
  name: 'profileStateSlice',
  initialState,
  reducers: {
    updateBio: (state: ProfileStateType, action: PayloadAction<string>) => {
      state.bio = action.payload;
    },
  },
  extraReducers: builder => {
    uploadProfilePictureCases(builder);
  },
});

const uploadProfilePictureCases = (
  builder: ActionReducerMapBuilder<ProfileStateType>
) => {
  builder.addCase(
    uploadProfilePicture.pending,
    (state: ProfileStateType) => {}
  );
  builder.addCase(
    uploadProfilePicture.fulfilled,
    (state: ProfileStateType, action: PayloadAction<any>) => {
      state.profilePicture = action.payload?.avatar;
    }
  );
  builder.addCase(
    uploadProfilePicture.rejected,
    (state: ProfileStateType, action: any) => {}
  );

  return builder;
};

export const { updateBio } = profileSlice.actions;
export default profileSlice.reducer;
