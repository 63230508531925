import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from '@reduxjs/toolkit';

import {
  getUserByEmail,
  getUserByUsername,
  loginUser,
  signUpUser,
} from '../actions/authActions';

const initialState = {
  userId: undefined,
  email: '',
  username: '',
  authToken: '',
  isUserLoading: false,
  isLoggedIn: false,
  isUserFound: false,
  loginErrorMessage: '',
} satisfies UserStateType as UserStateType;

const userSlice = createSlice({
  name: 'userStateSlice',
  initialState,
  reducers: {
    resetUserState: () => initialState,
    setUserEmail: (state: UserStateType, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
  extraReducers: builder => {
    userLoginCases(builder);
    userSignUpCases(builder);
    getUserByEmailCases(builder);
    getUserByUsernameCases(builder);
  },
});

const userLoginCases = (builder: ActionReducerMapBuilder<UserStateType>) => {
  builder.addCase(loginUser.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    loginUser.fulfilled,
    (state: UserStateType, action: PayloadAction<any>) => {
      state.isUserLoading = false;
      state.isLoggedIn = true;
      state.loginErrorMessage = '';
      state.authToken = action.payload.auth_token;
    }
  );

  builder.addCase(loginUser.rejected, (state: UserStateType, action: any) => {
    state.isUserLoading = false;
    state.isLoggedIn = false;
  });

  return builder;
};

const userSignUpCases = (builder: ActionReducerMapBuilder<UserStateType>) => {
  builder.addCase(signUpUser.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    signUpUser.fulfilled,
    (state: UserStateType, action: PayloadAction<SignupLoginSuccessType>) => {
      state.isUserLoading = false;
      state.isLoggedIn = true;
      state.userId = action.payload.id;
      state.username = action.payload.username;
      state.email = action.payload.email;
    }
  );
  // TODO: add type for action PayloadAction<UserAuthErrorType>
  builder.addCase(signUpUser.rejected, (state: UserStateType) => {
    state.isUserLoading = false;
  });
  return builder;
};

const getUserByEmailCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(getUserByEmail.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    getUserByEmail.fulfilled,
    (state: UserStateType, action: PayloadAction<getUserSuccessType>) => {
      state.email = action.payload.user.email
        ? action.payload.user.email
        : state.email;
      state.username = action.payload.user.username;
      state.isUserLoading = false;
    }
  );

  builder.addCase(getUserByEmail.rejected, (state: UserStateType) => {
    state.isUserLoading = false;
  });
  return builder;
};

const getUserByUsernameCases = (
  builder: ActionReducerMapBuilder<UserStateType>
) => {
  builder.addCase(getUserByUsername.pending, (state: UserStateType) => {
    state.isUserLoading = true;
  });

  builder.addCase(
    getUserByUsername.fulfilled,
    (state: UserStateType, action: PayloadAction<getUserSuccessType>) => {
      state.isUserLoading = false;
      // state.username = action.payload.username;
      // state.email = action.payload.email;
    }
  );

  builder.addCase(
    getUserByUsername.rejected,
    (state: UserStateType, action: any) => {
      state.isUserLoading = false;
      // TODO: edit state for this response
    }
  );
  return builder;
};

export const { resetUserState, setUserEmail } = userSlice.actions;
export default userSlice.reducer;
