import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetRequest } from './requestUtil';

// TODO: Split into 3 separate actions
// getRecommendedBlogs
// getMostPopularBlogs
// getRandomBlogs

// ! for now this is for testing getting a list of blogs
export const getBlogList = createAsyncThunk('get-blogs', async () => {
  console.log('getBlogs action called');

  const result: any = await sendGetRequest(`/api/blogs/`); //BlogListSuccessType

  console.log('result:', result);

  return result;
});

// export const getBlogListNextPage = createAsyncThunk('get-blogs-next-page', async () => {
//   console.log('getBlogs action called');

//   const result = await sendGetRequest(`api/blogs/`);

//   console.log('result:', result);

//   return result;
// });
