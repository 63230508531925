import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendGetRequest, sendPostRequest } from './requestUtil';

export const getAllSubjectTags = createAsyncThunk('sign-up-user', async () => {
  return sendGetRequest('api/tags/');
});

export const uploadProfilePicture = createAsyncThunk(
  'upload-profile-picture',
  async (body: any) => {
    return sendPostRequest('/api/profile/avatar/', body);
  }
);
