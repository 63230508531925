import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './hoc/Layout';
import './scss/index.scss';

const LoadHomePage = lazy(() => import('./containers/HomePage'));

const LoadLoginOrSignUpPage = lazy(
  () => import('./containers/authContainers/LoginOrSignUpPage')
);
const LoadLoginPage = lazy(
  () => import('./containers/authContainers/LoginPage')
);
const LoadSearchResultsPage = lazy(
  () => import('./containers/SearchResultsPage')
);
const LoadSignUpPage = lazy(
  () => import('./containers/authContainers/SignUpPage')
);
const LoadCreateProfilePage = lazy(
  () => import('./containers/authContainers/CreateProfilePage')
);

const LoadDonationPage = lazy(() => import('./containers/DonatePage'));

const LoadDonationSuccessPage = lazy(
  () => import('./containers/DonationSuccessPage')
);

const LoadBlogPage = lazy(() => import('./containers/blogContainers/BlogPage'));

const LoadUploadBlogPage = lazy(
  () => import('./containers/blogContainers/AddBlogPage')
);

const LoadBlogDiscussionPage = lazy(
  () => import('./containers/blogContainers/BlogDiscussionPage')
);

const LoadProfilePage = lazy(
  () => import('./containers/authContainers/ProfilePage')
);

const App = () => {
  return (
    <Layout>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/home" Component={LoadHomePage} />
          <Route path="/login-or-signup" Component={LoadLoginOrSignUpPage} />
          <Route path="/sign-up" Component={LoadSignUpPage} />
          <Route path="/login" Component={LoadLoginPage} />
          <Route path="/search-results" Component={LoadSearchResultsPage} />
          <Route path="/create-profile" Component={LoadCreateProfilePage} />
          <Route path="/blog/:blog_slug/:blog_id" Component={LoadBlogPage} />
          <Route
            path="/blog/:blog_slug/:blog_id/discussion/:discussion_slug"
            Component={LoadBlogDiscussionPage}
          />

          <Route path="/donate" Component={LoadDonationPage} />
          <Route path="/donation-success" Component={LoadDonationSuccessPage} />
          <Route path="/donation-canceled" Component={LoadDonationPage} />

          <Route path="/add-blog" element={<LoadUploadBlogPage />} />

          <Route
            path="/blog/:blog_slug/:blog_id/discussion/:discussion_slug/:discussion_id"
            Component={LoadBlogDiscussionPage}
          />
          <Route path="/profile" Component={LoadProfilePage} />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
